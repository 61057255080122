<template>
  <div>
    <card class="shadow" body-classes="standard-card-body">
      <div slot="header">
        <h4 class="card-title text-capitalize">{{ $t('deal.upload_documents') }}</h4>
      </div>
      <octo-upload-cloud-updated
        show-full-loading
        :presigned-url-action="endpoints.USER_MEDIA_PRESIGNED_URL.replace('{id}', user.id)"
        :store-url-action="endpoints.USER_STORE_MEDIA.replace('{id}', user.id)"
        :tag-list="mediaLabels"
        @onSuccessUpload="successUpload"
      />
    </card>
    <user-media-list :media="localMedia" @onDeleteMedia="deleteMedia"/>
  </div>
</template>

<script>

import {endpoints} from "@/routes/endpoints";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import BaseAlert from "@/components/BaseAlert";
import Badge from "@/components/Badge";
import OctoUploadCloudUpdated from "@/components/octo-upload-cloud/OctoUploadCloudUpdated";
import UserMediaList from "@/pages/Users/components/UserMediaList.vue";
import User from "@/models/user";

export default {
  name: "UserMedia",
  components: {OctoUploadCloudUpdated, UserMediaList, Badge, BaseAlert, OctoIcon},
  data() {
    return {
      endpoints: endpoints,
      localMedia: [],
      mediaLabels: [
        'contract',
        'id_card_front',
        'id_card_back',
        'id_card_front_back',
        'cv',
        'other',
      ]
    }
  },
  props: {
    user: {
      type: Object,
      default: () => this.$_.cloneDeep(User)
    }
  },
  mounted() {
    this.localMedia = this.$_.cloneDeep(this.user.media);
  },
  methods: {
    successUpload: function (media) {
      this.localMedia.unshift(media);
    },
    deleteMedia(id) {
      this.localMedia = this.$_.filter(this.localMedia, media => {
        return media.id !== id
      });
    }
  }
}
</script>

<style scoped>

</style>
