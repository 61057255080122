<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-plain">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="text-uppercase m-3">{{ $t('common.personal_data') }}</div>
            </template>
            <div class="row">
              <div class="col-12">
                <octo-header-button
                  :buttons="headerButtons"
                  :title="user.firstname + ' ' + user.lastname"
                  @onSaveUser="saveUser"
                  @onGenerateNewPassword="generateNewPassword"
                />
              </div>
              <div class="col-12 col-md-6">
                <card body-classes="standard-card-body" class="shadow">
                  <div slot="header"><h4 class="card-title text-capitalize">{{ $t('page.user_data') }}</h4></div>
                  <user-form :user="user" ref="userForm"/>
                </card>
                <octo-entity-addresses
                  :key="`addresses-${userDataLoadKey}`"
                  :addresses="user.addresses"
                  ref="octoEntityAddresses"
                />
              </div>
              <div class="col-12 col-md-6">
                <octo-entity-emails :key="`emails-${userDataLoadKey}`" :emails="user.emails" ref="octoEntityEmails"/>
                <octo-entity-phones :key="`phones-${userDataLoadKey}`" :phones="user.phones" ref="octoEntityPhones"/>
              </div>
            </div>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="text-uppercase m-3">{{ $t('common.roles') }}</div>
            </template>
            <octo-header-button
              :buttons="rolesButtons"
              :title="user.firstname + ' ' + user.lastname"
              @onSaveRoles="saveRoles"
            />
            <user-roles :roles="user.roles" ref="userRole" :key="userDataLoadKey"/>
          </tab-pane>

          <tab-pane v-if="showMediaTab">
            <template slot="label">
              <div class="text-uppercase m-3">{{ $t('common.media') }}</div>
            </template>
            <octo-header-button :title="user.firstname + ' ' + user.lastname"/>
            <user-media :user="user"/>
          </tab-pane>

          <tab-pane v-if="showAccessTab">
            <template slot="label">
              <div class="text-uppercase m-3">{{ $t('common.log_event') }}</div>
            </template>
            <octo-header-button :title="user.firstname + ' ' + user.lastname"/>
            <user-event-log-datatable/>
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import UserForm from "@/pages/Users/forms/UserForm";
import {endpoints} from "@/routes/endpoints";
import User from "@/models/user";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import UserRoles from "@/pages/Users/components/UserRoles";
import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";
import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";
import UserEventLogDatatable from "@/pages/Users/datatables/UserEventLogDatatable";
import {TabPane, Tabs} from '@/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {mapMutations} from "vuex";
import UserMedia from "@/pages/Users/components/UserMedia.vue";

export default {
  name: "ShowPage",
  components: {
    UserMedia,
    OctoIcon,
    UserEventLogDatatable,
    OctoEntityAddresses,
    OctoEntityPhones,
    OctoEntityEmails,
    UserRoles,
    OctoHeaderButton,
    UserForm,
    Tabs,
    TabPane,
  },
  data() {
    return {
      endpoints: endpoints,
      user: this.$_.cloneDeep(User),
      userDataLoadKey: 1,
      rolesButtons: [
        {
          label: 'save',
          onClick: 'onSaveRoles',
        }
      ]
    }
  },
  computed: {
    headerButtons: function () {
      const buttons = [];
      if (this.user.defaultEmail || this.user.emails.length > 0) {
        buttons.push({
          label: 'reset_password',
          onClick: 'onGenerateNewPassword',
        })
      }
      buttons.push({
        label: 'save',
        onClick: 'onSaveUser',
      });

      return buttons;
    },
    showAccessTab: function () {
      return !!this.user.id;
    },
    showMediaTab: function () {
      return !!this.user.id;
    },

  },
  beforeMount() {
    const userId = this.$route.params.id;

    this.$fullLoading.show();

    this.$api.get(endpoints.USER_SHOW.replace('{id}', userId))
      .then((resp) => {
        this.user = resp.data.data;
        this.userDataLoadKey++;
        this.$fullLoading.hide();
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$fullLoading.hide();
        this.$router.push({name: 'users.index'})
      })
  },
  methods: {
    ...mapMutations({
      updateUsers: 'common/updateUsers'
    }),

    saveUser: async function () {
      try {
        this.$fullLoading.show();
        const userData = await this.$refs.userForm.getUserData();
        userData.emails = this.$refs.octoEntityEmails.getEntityEmails();
        userData.phones = this.$refs.octoEntityPhones.getEntityPhones();
        userData.addresses = this.$refs.octoEntityAddresses.getEntityAddresses();

        const resp = await this.$api.put(
          endpoints.USER_UPDATE.replace('{id}', userData.id),
          userData
        );

        await this.updateUsers(resp?.data?.data);

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    generateNewPassword: function () {
      this.$fullLoading.show();

      this.$api.put(endpoints.USER_GENERATE_NEW_PASSWORD.replace('{id}', this.$route.params.id))
        .then(() => {
          this.$notify({type: 'success', message: this.$t('notifications.password_updated')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.password_not_updated')});
        })
        .finally(() => {
          this.$fullLoading.hide();
        })
    },

    saveRoles: async function () {
      try {
        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.USER_UPDATE_ROLES.replace('{id}', this.user.id),
          {roles: await this.$refs.userRole.getUserRoles()}
        );

        await this.updateUsers(resp?.data?.data);

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },
  }
}
</script>

<style scoped>

</style>
